import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import oauth from "@/shared/components/Oauth";
import store from "@/store/index";
import Admin from "@/views/Admin";
import {get_before_router} from "@/shared/mixins/router_helper";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: "/login/activate/:token/:refresh_token",
        name: "Activate",
        component: oauth,
        props: true
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
    },
    {
        path: "/admin/:tab_url",
        name: "AdminTab",
        component: Admin,
        props: true
    }
]

const router = new VueRouter({
    mode: "history",
    routes
})

let before_router = get_before_router(router)
router.beforeEach(async (to, from, next) => {
        return await before_router(to, from, next)
    }
)

export default router
