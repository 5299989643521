<template>
    <div>
        <v-tabs v-model="tab">
            <v-tab to="/admin/question_log" key="question_log">
                Question Log
            </v-tab>
            <v-tab to="/admin/answers" key="answers">
                Answers
            </v-tab>
            <v-tab to="/admin/users" key="users">
                Users
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item id="/admin/question_log" key="question_log" v-if="tab==='/admin/question_log'">
                <div>
                    <inde-table-view  resource="question_log">
                        <template #item.actions="{ item }">
                            <v-icon
                                @click.stop="duplicate(item)" >
                                mdi-duplicate-item
                            </v-icon>
                        </template>
                    </inde-table-view>
                </div>
            </v-tab-item>
            <v-item id="/admin/answers" key="answers" v-if="tab==='/admin/answers'">
                <div>
                    <inde-table-view :headers="answers_headers" resource="answers">
                    </inde-table-view>
                </div>
            </v-item>
            <v-tab-item id="/admin/users" key="users">
                <div>
                    <inde-table-view :headers="user_headers" resource="users">
                    </inde-table-view>
                </div>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import IndeTableView from "@/shared/components/view/inde_table_view";
import {mapActions} from "vuex";

export default {
    name: "admin-component",
    components: {IndeTableView},
    mixins: [],
    data: function () {
        return {
            tab: "",
            item: {
                answer: "",
                keywords: [],
                when: false,
                where: false,
                how: false,
                which: false,
                what: false,
            },
            tag: "",
            question_log_headers: [
                {
                    value: "question",
                    text: "question"
                },
                {
                    value: "answer",
                    text: "Answer"
                },
                {
                    value: "ip_address",
                    text: "Ip address"
                },
                {
                    value: "_created",
                    text: "Timestamp"
                },
                {
                    value: "action.add_answer",
                    text: "Add answer"
                }
            ],
            answers_headers: [
                {
                    value: "answer",
                    text: "answer"
                },
                {
                    value: "keywords",
                    text: "keywords"
                },
            ],
            user_headers: [
                {
                    value: "full_name",
                    text: "Full name"
                },
                {
                    value: "is_admin",
                    text: "Admin"
                }
            ]
        }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>