import Vuex from 'vuex'
import Vue from 'vue'
import {auth_store, create_persistent_storage} from "@/shared/store/auth";
import {variables_store} from "@/store/variables";
import {dialog_store} from "@/shared/store/dialog_store";


Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        auth: auth_store,
        variables: variables_store,
        dialog: dialog_store,
    },
    plugins: [create_persistent_storage(["auth"]).plugin],

})
