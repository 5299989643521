import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import Toasted from 'vue-toasted'
import store from '@/store/index'
import {vuetify} from '@/shared/init'
import i18n from '@/shared/i18n'

Vue.config.productionTip = false
Vue.use(Toasted, {
    action: {
        text: 'X',
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        }
    },
    duration: 2000
})
new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
