export const variables_store = {
    namespaced: true,
    state() {
        return {
            links: {
                admin: [{
                    url: "/admin",
                    name: "admin",
                    locale: "admin"
                }],
                common: []
            }
        }
    }
}