<template>
    <div>
        <v-row v-if="!current_user" justify="center">
            <v-btn large @click="internal_send_to_google"> Login</v-btn>
        </v-row>
        <v-row v-else-if="!current_user.is_admin">
            <v-col>
                You are not admin
            </v-col>
        </v-row>
        <admin-component v-else>
        </admin-component>

    </div>
</template>

<script>

import {token_mixin} from "@/shared/mixins/token";
import {mapActions, mapGetters} from "vuex";
import AdminComponent from "@/components/admin/admin-component";

export default {
    name: "Admin",
    components: {AdminComponent},
    mixins: [token_mixin],
    props: {
        tab_url: {
            type: String,
            default: null
        }
    },
    methods: {
        ...mapActions({send_to_login: "auth/send_to_login"}),
        async internal_send_to_google() {
            await this.send_to_login({next: {name: 'Admin'}, id_type: 'google'})
        }

    },
    computed: {
        ...mapGetters({current_user: "auth/current_user"})
    }

}
</script>

<style scoped>

</style>